@import "@aws-amplify/ui-react/styles.css";
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');


@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    html {
        font-family: 'Roboto Mono', system-ui, sans-serif;
    }
}

[contenteditable] {
    outline: 0px solid transparent;
}

.loader {
    width: 100%;
    height: 3px;
    position: relative;
    overflow: hidden;
    /* background-color: #ddd; */
    background-color: white;
    /* margin: 100px auto; */
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
}

.loader:before {
    content: "";
    position: absolute;
    left: -50%;
    height: 3px;
    /* width: 40%; */
    /* background-color: slateblue; */
    -webkit-animation: lineAnim 2s forwards infinite;
    -moz-animation: lineAnim 2s forwards infinite;
    animation: lineAnim 2s forwards infinite;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
}

@keyframes lineAnim {
    0% {
        left: 0%;
        width: 0%;
    }

    50% {
        left: 20%;
        width: 70%;
    }

    100% {
        left: 100%;
        width: 100%;
    }
}